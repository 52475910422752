<template>
  <div class="container_view">
    <van-nav-bar :fixed="true" class="van_nav_bar_view" left-arrow>
      <template #left>
        <img
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          @click="golink"
          alt=""
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">选择手机号归属地</span>
      </template>
    </van-nav-bar>
    <div style="margin-top: 50px">
      <div v-for="(item, index) in cityList" :key="index">
        <div class="city_index_view">{{ item.cityIndex }}</div>
        <div
          :class="
            cityListItem.isSelect ? 'city_title_view' : 'city_title_gray_view'
          "
          v-for="(cityListItem, cityListIndex) in item.infoList"
          :key="cityListIndex"
          @click="handelItem(cityListItem)"
        >
          <span>{{ cityListItem.cityName }}</span>
          <span>{{ cityListItem.phone }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import country from "@/utils/country.json";
export default {
  data() {
    return {
      cityList: country,
      option: 1,
      class: "",
      form:""
    };
  },
  mounted() {
    // console.log("aaaaa" + this.$route.query.option);
    this.option = this.$route.query.option;
    this.class = this.$route.query.class;
    this.form = this.$route.query.form;
  },
  // canActivate(transition) {
  //   console.log(transition, "======上一个页面的url信息=======");
  //   // transition.next();
  // },
  methods: {
    handelItem(item) {
      if (this.option == 0 || this.option == 1) {
        this.$router.push({
          path: "login",
          query: {
            code: item.phone,
            countryCode: item.countryCode,
            option: this.$route.query.option,
          },
        });
      } else if (this.option == 2) {
        this.$router.replace({
          path: "/newAddress",
          query: {
            code: item.phone,
            countryCode: item.countryCode,
            addr: this.$route.query.addr,
          },
        });
      } else if (this.option == 3) {
        this.$router.push({
          path: "/forgetPassword",
          query: {
            code: item.phone,
            countryCode: item.countryCode,
          },
        });
      } else if (this.option == 4) {
        this.$router.replace({
          path: "/changePwd",
          query: {
            code: item.phone,
            countryCode: item.countryCode,
            option: this.class,
          },
        });
      }
    },
    golink(){
      this.$router.replace({
        path:this.form,
      })
    }
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .van_nav_bar_view {
    margin-bottom: 10px;
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  body {
    max-width: 540px;
  }
  .container_view {
    max-width: 540px;
  }
}
.city_index_view {
  height: 26px;
  background: #f1f5f6;
  padding-left: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #787d84;
  font-weight: 400;
}
.city_title_view {
  width: 100%;
  height: 45px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  span:first-of-type {
    margin-left: 15px;
    font-size: 15px;
    color: #00b05a;
  }
  span:last-of-type {
    margin-right: 15px;
    font-size: 14px;
    color: #00b05a;
  }
}
.city_title_gray_view {
  width: 100%;
  height: 45px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  span:first-of-type {
    margin-left: 15px;
    font-size: 15px;
    color: #000000;
  }
  span:last-of-type {
    margin-right: 15px;
    font-size: 14px;
    color: #acacac;
  }
}
</style>
